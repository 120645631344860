import {
  defaultEmployee,
  employeeTypesMap,
} from "../../constants/employeeList";

export const actionTypes = {
  SET_IMAGE: "SET_IMAGE",
  SET_ROLE: "SET_ROLE",
  SET_FULLNAME: "SET_FULLNAME",
  SET_LINCENSE_ID: "SET_LINCENSE_ID",
  SET_PHONE_NO: "SET_PHONE_NO",
  SET_EMAIL: "SET_EMAIL",
  SET_PRACTICE: "SET_PRACTICE",
  RESET: "RESET",
  SET_DIRTY: "SET_DIRTY",
  SET_EMPLOYEE: "SET_EMPLOYEE",
};

export const defaultState = {
  employee: defaultEmployee,
  isFormDirty: false,
};

export const employeeFormReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SET_IMAGE:
      return {
        ...state,
        employee: {
          ...state.employee,
          image: { ...state.employee.image, encodedImage: action.payload },
        },
      };
    case actionTypes.SET_ROLE:
      return {
        ...state,
        employee: {
          ...state.employee,
          employeeType: {
            ...action.payload
            // ...state.employee.employeeType,
            //  state.employee.id ? action.payload : employeeTypesMap[action?.payload.label?.toUpperCase()],
          },
        },
      };
    case actionTypes.SET_FULLNAME:
      return {
        ...state,
        employee: {
          ...state.employee,
          fullName: action.payload,
        },
      };
    case actionTypes.SET_LINCENSE_ID:
      return {
        ...state,
        employee: {
          ...state.employee,
          licenceId: action.payload,
        },
      };
    case actionTypes.SET_PHONE_NO:
      return {
        ...state,
        employee: {
          ...state.employee,
          phone: action.payload,
        },
      };
    case actionTypes.SET_EMAIL:
      return {
        ...state,
        employee: {
          ...state.employee,
          email: action.payload,
        },
      };
    case actionTypes.SET_PRACTICE:
      return {
        ...state,
        employee: {
          ...state.employee,
          practice: action.payload,
        },
      };

    case actionTypes.SET_DIRTY:
      return {
        ...state,
        isFormDirty: true,
      };

    case actionTypes.RESET:
      return {
        ...defaultState,
      };

    case actionTypes.SET_EMPLOYEE:
      return {
        ...state,
        employee: action.payload,
      };

    default:
      return state;
  }
};
