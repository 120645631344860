import React from "react";
import { Modal as ReactstrapModal, ModalBody, Button } from "reactstrap";
import cx from "classnames";

import styles from "./modal.module.scss";

const ConfirmationModal = ({
  isOpen,
  onConfirm,
  onCancel,
  title,
  subtitle,
  confirmTitle,
  cancelTitle,
}) => {
  return (
    <ReactstrapModal isOpen={isOpen} className={styles.modal} centered>
      <ModalBody className={styles.body}>
        {title && (
          <p className={cx(styles.title, { [styles.noSubtitle]: !subtitle })}>
            {title}
          </p>
        )}
        {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
        <div className={styles.buttonContainer}>
          <Button
            data-testid="confirmationModalConfirm"
            color="primary"
            className={cx(styles.button, styles.buttonPrimary)}
            onClick={onConfirm}
          >
            {confirmTitle}
          </Button>
          <Button
            data-testid="confirmationModalCancel"
            color="primary"
            className={styles.button}
            onClick={onCancel}
          >
            {cancelTitle}
          </Button>
        </div>
      </ModalBody>
    </ReactstrapModal>
  );
};

ConfirmationModal.defaultProps = {
  title: "Are you sure?",
  confirmTitle: "Yes",
  cancelTitle: "Cancel",
};

export default ConfirmationModal;
