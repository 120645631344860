/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Table, Label } from 'reactstrap';
import _get from 'lodash.get';
import { useTranslation } from 'react-i18next';

import Avatar from '../Avatar';
import { bin, edit } from '../../constants/icons';
import TableHeader from '../TableHeader';
import ToggleMenu from '../../containers/ToggleMenu';
import styles from './employeeList.module.scss';
import EmployeeForm from '../EmployeeForm/';
import ViewEmployeeDetail from '../ViewEmployeeDetail/';

const EmployeeList = ({
  'data-testid': dataTestId,
  employees,
  onRemove,
  refetchEmployees,
  updateExistingEmployee,
}) => {
  const [lastSelected, setLastSelected] = useState('');
  const [highlightColumn, setHighlightColumn] = useState('column-4');
  const [sortAssending, setSortAssending] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showEmployeeForm, setShowEmployeeForm] = useState(false);
  const [showNestedModal, setShowNestedModal] = useState(false);
  const [employee, setEmployee] = useState({});
  const { t } = useTranslation();

  const sortData = (key, order) => {
    return function innerSort(a, b) {
      let variableA, variableB;
      const valueA = _get(a, key);
      const valueB = _get(b, key);
      variableA = typeof valueA === 'string' ? valueA.toUpperCase() : valueA;
      variableB = typeof valueB === 'string' ? valueB.toUpperCase() : valueB;
      let comparison = 0;
      if (variableA > variableB) {
        comparison = 1;
      } else if (variableA < variableB) {
        comparison = -1;
      }
      return order ? comparison * -1 : comparison;
    };
  };

  const handleClick = (param) => {
    if (lastSelected === param) {
      employees.sort(sortData(param, !sortAssending));
      setSortAssending(!sortAssending);
    } else {
      setSortAssending(false);
      employees.sort(sortData(param, false));
      setLastSelected(param);
    }
  };

  const onClickEdit = (emp) => {
    setEmployee(emp);
    toggleModal();
  };

  const getToggleItems = (emp) => [
    {
      label: t('common.editInfo'),
      handleClick: () => onClickEdit(emp),
      logo: edit,
      dataTestId: 'editEmployee',
    },
    {
      label: t('common.remove'),
      handleClick: () => onRemove(emp),
      logo: bin,
      dataTestId: 'removeEmployee',
    },
  ];

  const commonProps = {
    highlightColumn,
    setHighlightColumn,
    sortAssending,
    handleClick,
  };

  const onShowDetail = (employee) => {
    setEmployee(employee);
    setShowDetailModal(!showDetailModal);
  };

  const handleUnsetEmployee = () => {
    // unset employee when closing modal
    if (showEmployeeForm || showDetailModal || showNestedModal) {
      setEmployee({});
    }
  };

  const toggleModal = () => {
    handleUnsetEmployee();
    setShowEmployeeForm(!showEmployeeForm);
  };

  const toggleNestedModal = () => {
    handleUnsetEmployee();
    setShowNestedModal(!showNestedModal);
  };

  const toggleShowDetailModal = () => {
    handleUnsetEmployee();
    setShowDetailModal(!showDetailModal);
  };

  const onEditingInfo = (emp) => {
    toggleShowDetailModal();
    toggleModal();
    setEmployee(emp);
  };

  const onDeleteEmployee = (emp) => {
    onRemove(emp);
    toggleShowDetailModal();
  };

  return (
    <>
      <div data-testid={dataTestId} className={styles.rootWrapper}>
        <Table data-testid="employeeTable">
          <thead>
            <tr>
              <TableHeader
                data-testid="employeeList-name"
                label={t('employeeList.name')}
                columnName="column-1"
                sortLabel="firstName"
                {...commonProps}
              />
              <TableHeader
                data-testid="employeeList-role"
                label={t('addEmployee.form.role')}
                columnName="column-2"
                sortLabel="employeeType.name"
                {...commonProps}
              />
              <TableHeader
                data-testid="employeeList-email"
                label={t('addEmployee.form.email')}
                columnName="column-3"
                sortLabel="email"
                {...commonProps}
              />
              <TableHeader
                data-testid="employeeList-currentPractice"
                label={t('employeeList.currentPractice')}
                columnName="column-4"
                sortLabel="practice"
                {...commonProps}
              />
              <TableHeader
                data-testid="employeeList-status"
                label={t('employeeList.status')}
                columnName="column-5"
                sortLabel="status"
                {...commonProps}
              />
              <th />
            </tr>
          </thead>
          <tbody>
            {employees.map((emp, index) => (
              <tr key={emp.id} data-testid={`employeeRow-${emp.id}`}>
                <td
                  id={`employeeColumn${index}`}
                  className={styles.tableRow}
                  onClick={() => onShowDetail(emp)}
                >
                  <div
                    className={
                      highlightColumn === 'column-1'
                        ? styles.highlightNameWrapper
                        : styles.nameWrapper
                    }
                  >
                    <div>
                      <Avatar employee={emp} className={styles.defImage} />
                    </div>
                    <div className={styles.ageWrapper}>
                      {`${emp.firstName} ${emp.lastName}`}

                      <div>
                        <Label className={styles.customformLabel}>
                          {emp.licenceId}
                        </Label>
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  className={
                    highlightColumn === 'column-2'
                      ? styles.highlightAddress
                      : styles.address
                  }
                  onClick={() => onShowDetail(emp)}
                >
                  {emp?.employeeType?.type
                    ? t(`employeeType.${emp.employeeType.type.toLowerCase()}`)
                    : '-'}
                </td>
                <td
                  className={
                    highlightColumn === 'column-3'
                      ? styles.highlightDob
                      : styles.dob
                  }
                  onClick={() => onShowDetail(emp)}
                >
                  {emp.email}
                </td>
                <td
                  className={
                    highlightColumn === 'column-4'
                      ? styles.highlightAppointmentContent
                      : styles.appointmentContent
                  }
                  onClick={() => onShowDetail(emp)}
                >
                  {emp.practice?.label || ''}
                </td>
                <td
                  className={
                    highlightColumn === 'column-6'
                      ? styles.highlightDob
                      : styles.dob
                  }
                  onClick={() => onShowDetail(emp)}
                >
                  {emp?.status
                    ? t(`employeeStatus.${emp.status.toLowerCase()}`)
                    : '-'}
                </td>
                <td className={styles.archiveRow}>
                  <ToggleMenu items={getToggleItems(emp)} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {showDetailModal && (
        <ViewEmployeeDetail
          modal={showDetailModal}
          toggle={toggleShowDetailModal}
          employee={employee}
          onEditingInfo={onEditingInfo}
          onDeleteEmployee={onDeleteEmployee}
        />
      )}
      {showEmployeeForm && (
        <EmployeeForm
          showModal={showEmployeeForm}
          toggleModal={toggleModal}
          showNestedModal={showNestedModal}
          toggleNestedModal={toggleNestedModal}
          refetchEmployees={refetchEmployees}
          updateExistingEmployee={updateExistingEmployee}
          employee={employee}
        />
      )}
    </>
  );
};

export default EmployeeList;
