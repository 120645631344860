export const getManipulatedEmployeeData = (employeeData) => {
  const {
    user,
    employee,
    assignedOrganizationalUnit: { organizationalUnits },
  } = employeeData;

  const userData = user?.data;

  const manipulatedData = {
    id: user?.data?.employee?.value?.id,
    auth0Id: user?.data?.auth0Id,
    userId: user?.ref?.value?.id,
    orgId: process.env.REACT_APP_ORGANIZATION_ID,
    invitationId: organizationalUnits?.[0]?.auth0InvitationId,
    tenant: organizationalUnits?.[0]?.organizationalUnit?.data?.name || "",
    status: employee?.data?.status,
    practice: {
      ...(organizationalUnits?.[0]?.organizationalUnit?.data || {}),
      label: organizationalUnits?.[0]?.organizationalUnit?.data?.name,
      value: organizationalUnits?.[0]?.organizationalUnit?.ref?.value?.id,
    },
    employeeType: {
      ...organizationalUnits?.[0]?.roles?.[0]?.data,
    },
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    email: userData?.email,
    image: userData?.image,
    phone: userData?.phone,
  };

  return manipulatedData;
};
