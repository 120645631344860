import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './titleBar.module.scss';
import { plusIcon } from '../../constants/icons';
import EmployeeForm from '../EmployeeForm';

const TitleBar = ({ 'data-testid': dataTestId, title, refetchEmployees }) => {
  const [showModal, setShowModal] = useState(false);
  const [showNestedModal, setShowNestedModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);
  const toggleNestedModal = () => setShowNestedModal(!showNestedModal);
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className={styles.rootWrapper} data-testid={dataTestId}>
        <div className={styles.title}>{title}</div>
        <div
          id="newEmployeeButton"
          data-testid="newEmployeeButton"
          className={styles.buttonWrapper}
          onClick={() => setShowModal(true)}
        >
          <div className={styles.plusWrapper}>
            <div className={styles.plus}>
              <img src={plusIcon} alt="+" className={styles.plusIcon} />
            </div>
          </div>
          <button className={styles.newBtn}>{t('New employee')}</button>
        </div>
      </div>
      {showModal && (
        <EmployeeForm
          showModal={showModal}
          toggleModal={toggleModal}
          showNestedModal={showNestedModal}
          toggleNestedModal={toggleNestedModal}
          refetchEmployees={refetchEmployees}
          employee={{}}
        />
      )}
    </Fragment>
  );
};

export default TitleBar;
