import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import { useTranslation } from "react-i18next";

import styles from "./nestedModal.module.scss";

const NestedModal = ({
  showNestedModal,
  toggleNestedModal,
  closeAllModals,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={showNestedModal} className={styles.nestedModal}>
      <ModalBody className={styles.warningModal}>
        <img
          src="https://storage.googleapis.com/dechea-microservices-files/patient-management/polygon.svg"
          className={styles.polygon}
          alt="polygon"
        />
        <p className={styles.warningModalTitle}>
          {t("addEmployee.form.saveChanges")}
        </p>
        <p className={styles.warningModalBody}>
          {t("addEmployee.form.saveChangesDescription")}
        </p>
        <div className={styles.nestedButtonClass}>
          <Button
            color="primary"
            className={styles.nestedButton}
            onClick={() => {
              handleSubmit();
              toggleNestedModal();
            }}
          >
            {t("common.yes")}
          </Button>{" "}
          <Button
            color="primary"
            className={styles.nestedButton}
            onClick={closeAllModals}
          >
            {t("common.no")}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default NestedModal;
