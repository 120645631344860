export const SET_ROLES = "SET_ROLES";
export const GET_ROLES = "GET_ROLES";

export const getRoles = () => ({
  type: GET_ROLES,
});

export const setRoles = (roles) => {
  return {
    type: SET_ROLES,
    payload: roles,
  };
};
