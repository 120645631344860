/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  Row,
  Col,
} from 'reactstrap';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@dechea/orc.orc.dropdown';

import { Cameraimg, plusIcon, tickmark } from '../../constants/icons';
import DefaultImage from '../DefaultImage';
import NestedModal from '../NestedModal';
import FormGroupInput from '../FormGroupInput';
import styles from './employeeForm.module.scss';
import { ACTIVE_FIELDS } from '../../constants/options';
import { employeeTypesMap } from '../../constants/employeeList';
import ApiGatewayService from '../../api/apiGateway';
import {
  actionTypes,
  defaultState,
  employeeFormReducer,
} from './employeeFormReducer';
import {
  isValidEmailAddress,
  isValidName,
  isValidPhoneNumber,
} from '../../utils/validations';
import { checkRequiredFields } from './checkRequiredField';
import { displayNotification as displayNotificationAction } from '../../store/actions/notification';
import connect from '../../store/connect';
import { notificationTypes } from '../NotificationMessage';
import useGAEventTracker from '../../hooks/useGAEventTracker';
import { getManipulateEmployeeData } from '../../utils/conversion';
import { getAllOrganizationalUnit } from '../../store/actions/organizationalUnits';
import { getRoles } from '../../store/actions/roles';
import { checkEmailExists } from './checkEmailExists';

const validators = {
  name: isValidName,
  phone: isValidPhoneNumber,
  email: isValidEmailAddress,
  image: () => true,
};

const actions = {
  role: actionTypes.SET_ROLE,
  name: actionTypes.SET_FULLNAME,
  phone: actionTypes.SET_PHONE_NO,
  email: actionTypes.SET_EMAIL,
  practice: actionTypes.SET_PRACTICE,
  image: actionTypes.SET_IMAGE,
};

const EmployeeForm = ({
  showModal,
  toggleModal,
  showNestedModal,
  toggleNestedModal,
  employee,
  isDisabled,
  modalId,
  refetchEmployees,
  updateExistingEmployee,
  displayNotification,
  organizationalUnits,
  fetchedRoles,
}) => {
  const [state, dispatch] = useReducer(employeeFormReducer, defaultState);
  const { fullName, email, phone, practice, image, employeeType } =
    state?.employee;
  const [isActiveFields, setActiveFields] = useState(ACTIVE_FIELDS);
  const [requiredFieldErrors, setRequiredFieldError] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const { t } = useTranslation();
  const trackEvent = useGAEventTracker('Authorization');

  const url = new URLSearchParams(window.location.search);
  const lng = url.get('lng');

  useEffect(() => {
    if (employee.id) {
      dispatch({
        type: actionTypes.SET_EMPLOYEE,
        payload: {
          ...employee,
          fullName: `${employee?.firstName} ${employee?.lastName}`,
        },
      });
    }
  }, [employee]);

  const onChange = (fieldName, event) => {
    dispatch({ type: actions[fieldName], payload: event });
    if (!state?.isFormDirty) {
      dispatch({ type: actionTypes.SET_DIRTY });
    }

    if (fieldName === 'email') {
      const requiredFieldErrorsFiltered = requiredFieldErrors.filter(
        (field) => !field?.email
      );
      setRequiredFieldError(requiredFieldErrorsFiltered);
    }

    if (fieldName === 'phone') {
      let validatorErrorsWithPhone = {
        ...validationErrors,
      };
      if (event?.length) {
        validatorErrorsWithPhone[fieldName] = !validators[fieldName](event);
      } else {
        validatorErrorsWithPhone[fieldName] = false;
      }

      setValidationErrors(validatorErrorsWithPhone);
      return;
    }

    if (fieldName !== 'role' && fieldName !== 'practice') {
      setValidationErrors({
        ...validationErrors,
        [fieldName]: !validators[fieldName](event),
      });
    }
  };

  const toggleApiCall = () => {
    setIsSaving(!isSaving);
  };

  const roleOptions = fetchedRoles?.map((et) => ({
    id: et.roleId,
    auth0Id: et.auth0Id,
    label: t(`employeeType.${et.type.toLowerCase()}`),
    name: t(`employeeType.${et.type.toLowerCase()}`),
  }));

  const getOption = (id) => {
    const result = roleOptions?.filter((option) => option?.label === id?.label);
    if (result?.length) return result[0];
  };

  const getParsedName = () => {
    if (!fullName) {
      return [];
    }

    const [firstname, ...lastnames] = fullName.split(' ');
    let lastname = null;
    if (lastnames?.length) {
      lastname = lastnames.join(' ');
      return [firstname, lastname];
    }
    if (firstname) {
      return [firstname];
    }
    return [];
  };
  const closeModal = () => {
    toggleModal();
    dispatch({ type: actionTypes.RESET });
  };

  const closeAllModals = () => {
    toggleNestedModal();
    closeModal();
  };

  const handleClose = () => {
    if (state?.isFormDirty) {
      toggleNestedModal();
    } else {
      closeModal();
    }
  };

  const handleFormValidation = () => {
    const [firstName, lastName] = getParsedName();
    const employeeFields = {
      firstName,
      lastName,
      email,
      practice: practice?.id,
      role: employeeType.id,
    };

    return checkRequiredFields(employeeFields);
  };

  const handleSubmit = async () => {
    const errors = handleFormValidation();
    setRequiredFieldError(errors);

    if (Boolean(email)) {
      const isEmailExists = await checkAndSetEmailExistError(errors);

      if (isEmailExists) return null;
    }

    const isValidationError = Object.keys(validationErrors)
      .map((validationError) => {
        if (validationError !== 'phone') {
          return validationErrors[validationError];
        } else {
          if (validationError?.length) {
            return validationErrors[validationError];
          }
        }
      })
      .find((err) => err);

    if (errors.length || isValidationError) {
      return;
    }

    toggleApiCall();
    const [firstName, lastName] = getParsedName();
    if (employee.id) {
      // udpate existing employee details
      const updatedEmployee = {
        ...state?.employee,
        firstName,
        lastName,
        id: employee.id,
        employeeType: { id: state?.employee.employeeType.id },
      };

      if (
        ![employeeTypesMap.ADMINISTRATOR, employeeTypesMap.DOCTOR].includes(
          updatedEmployee.employeeType.id
        )
      ) {
        updatedEmployee.licenceId = '';
      }

      delete updatedEmployee.fullName;
      delete updatedEmployee.addresses;
      delete updatedEmployee.archived;
      delete updatedEmployee.tenant;

      ApiGatewayService.updateEmployee(employee.id, updatedEmployee)
        .then((result) => {
          updateExistingEmployee(result);
          displayNotification({
            type: notificationTypes.NOTE_CREATED,
            message: t('employee.updated'),
          });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          toggleApiCall();
          closeModal();
        });
    } else {
      // add new employee
      const newEmployee = { ...state.employee };
      newEmployee.firstName = firstName;
      newEmployee.lastName = lastName;

      const manipulateEmployeeData = getManipulateEmployeeData(newEmployee);

      // // Todo: update tenant when tenant data is available
      // newEmployee.tenant = { id: newEmployee.tenant.id };

      // const isGerman = lng === 'de';
      ApiGatewayService.addEmployee(manipulateEmployeeData)
        .then((result) => {
          trackEvent('Employee created');
          refetchEmployees();
          displayNotification({
            type: notificationTypes.NOTE_CREATED,
            message: t('employee.created'),
          });
        })
        .catch((error) => {
          displayNotification({
            type: notificationTypes.FAILED,
            message: t('employee.notification.failedToAdd'),
          });
          console.error(error);
        })
        .finally(() => {
          toggleApiCall();
          closeModal();
        });
    }
  };

  const getError = (fieldName) => {
    return requiredFieldErrors.find((errorObject) => errorObject[fieldName]);
  };

  const handleFilePick = (event) => {
    const file = event.target.files[0];

    if (file && file.type.match(/image.*/)) {
      // Load the image
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        const image = new Image();
        image.onload = () => {
          // Resize the image
          const canvas = document.createElement('canvas');
          const max_size = 100; // TODO: pick min size
          let width = image.width;
          let height = image.height;
          if (width > height) {
            if (width > max_size) {
              height *= max_size / width;
              width = max_size;
            }
          } else {
            if (height > max_size) {
              width *= max_size / height;
              height = max_size;
            }
          }
          canvas.width = width;
          canvas.height = height;
          canvas.getContext('2d').drawImage(image, 0, 0, width, height);
          const dataUrl = canvas.toDataURL('image/jpeg');
          onChange('image', window.btoa(dataUrl));
        };
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const checkAndSetEmailExistError = async (errors) => {
    if (validationErrors.email) return null;

    // @EMP_GENERATION_START@
    const isEmailExist = await checkEmailExists(email);
    const requiredFieldErrorsClone = errors
      ? [...errors]
      : [...requiredFieldErrors];

    if (isEmailExist) {
      const emailExistError = { email: t('addEmployee.form.userAlreadyExist') };
      const emailErrorIndex = requiredFieldErrorsClone.findIndex(
        (field) => field?.email
      );

      if (emailErrorIndex !== -1) {
        requiredFieldErrorsClone[emailErrorIndex] = emailExistError;
        setRequiredFieldError(requiredFieldErrorsClone);
      } else {
        setRequiredFieldError([...requiredFieldErrorsClone, emailExistError]);
      }
    } else {
      const requiredFieldErrorsCloneFiltered = requiredFieldErrorsClone.filter(
        (field) => !field?.email
      );

      setRequiredFieldError(requiredFieldErrorsCloneFiltered);
    }

    return isEmailExist;
    // @EMP_GENERATION_END@
  };

  return (
    <div className={styles.modalSection} id={modalId}>
      <Modal
        id="employeeModal"
        data-testid="employeeModal"
        isOpen={showModal}
        toggle={handleClose}
        className={styles.modalMain}
        fade={false}
      >
        <ModalHeader className={styles.modalHeader}>
          <div className={styles.mainpopupHeader}>
            {image?.encodedImage ? (
              <img
                src={window.atob(image.encodedImage)}
                className={styles.defImage}
                alt=""
              />
            ) : (
              <DefaultImage className={styles.defImage} />
            )}
            <div className={styles.uploadWrapper}>
              <button className={styles.browseButton}>
                {t('addEmployee.form.uploadPhoto')}
              </button>
              <input
                id="fileUploadButton"
                data-testid="fileUploadButton"
                type="file"
                name="myfile"
                onChange={handleFilePick}
              />
              <img src={Cameraimg} alt="" />
            </div>
          </div>
        </ModalHeader>
        <ModalBody className={styles.modalBody}>
          <div>
            <NestedModal
              showNestedModal={showNestedModal}
              toggleNestedModal={toggleNestedModal}
              closeAllModals={closeAllModals}
              handleSubmit={handleSubmit}
            />
            <Form>
              <Row>
                <Col sm={12} className={styles.formCol}>
                  <div className={styles.selectMenu}>
                    <Dropdown
                      label={t('addEmployee.form.role')}
                      options={roleOptions}
                      width={434}
                      error={getError('role')}
                      errorText={Object.values(getError('role') || {})[0]}
                      dataTestId="inputFieldRole"
                      value={getOption(employeeType) || ''}
                      onChange={(event) => onChange('role', event.target.value)}
                      disable={isDisabled}
                    />
                  </div>
                </Col>
                <Col sm={12} className={styles.formCol}>
                  <FormGroupInput
                    id="inputFieldName"
                    dataTestId="inputFieldName"
                    onFocus={() =>
                      setActiveFields({ ...isActiveFields, fullName: true })
                    }
                    onFocusOut={() =>
                      setActiveFields({ ...isActiveFields, fullName: false })
                    }
                    value={state?.employee?.fullName || ''}
                    style={styles.customInput}
                    handleChange={(event) => onChange('name', event)}
                    disable={isDisabled}
                    validate={isValidName}
                    requiredError={
                      getError('firstName') || getError('lastName')
                    }
                  />
                  <span
                    className={cx(
                      {
                        [styles.fieldPlaceholderInActive]:
                          !isActiveFields.fullName && fullName,
                      },
                      {
                        [styles.fieldPlaceholderActive]:
                          isActiveFields.fullName || fullName,
                      },
                      { [styles.fieldPlaceholder]: !isActiveFields.fullName }
                    )}
                  >
                    {t('addEmployee.form.fullName')}
                  </span>
                </Col>
                <Col sm={12} className={styles.formCol}>
                  <FormGroupInput
                    onFocus={() => {
                      setActiveFields({ ...isActiveFields, phoneNumber: true });
                    }}
                    onFocusOut={() => {
                      setActiveFields({
                        ...isActiveFields,
                        phoneNumber: false,
                      });
                    }}
                    id="inputFieldPhoneNumber"
                    dataTestId="inputFieldPhoneNumber"
                    value={phone}
                    style={styles.customInput}
                    handleChange={(event) => onChange('phone', event)}
                    disable={isDisabled}
                    validate={isValidPhoneNumber}
                  />
                  <span
                    className={cx(
                      {
                        [styles.fieldPlaceholderInActive]:
                          !isActiveFields.phoneNumber && phone,
                      },
                      {
                        [styles.fieldPlaceholderActive]:
                          isActiveFields.phoneNumber || phone,
                      },
                      { [styles.fieldPlaceholder]: !isActiveFields.phoneNumber }
                    )}
                  >
                    {`${t('addEmployee.form.phone')} (${t('common.optional')})`}
                  </span>
                </Col>
                <Col sm={12} className={styles.formCol}>
                  <FormGroupInput
                    onFocus={() => {
                      setActiveFields({ ...isActiveFields, email: true });
                    }}
                    onFocusOut={() => {
                      checkAndSetEmailExistError();
                      setActiveFields({ ...isActiveFields, email: false });
                    }}
                    id="inputFieldEmail"
                    dataTestId="inputFieldEmail"
                    value={email}
                    style={styles.customInput}
                    handleChange={(event) => onChange('email', event)}
                    disable={isDisabled}
                    validate={isValidEmailAddress}
                    requiredError={getError('email')}
                  />
                  <span
                    className={cx(
                      {
                        [styles.fieldPlaceholderInActive]:
                          !isActiveFields.email && email,
                      },
                      {
                        [styles.fieldPlaceholderActive]:
                          isActiveFields.email || email,
                      },
                      { [styles.fieldPlaceholder]: !isActiveFields.email }
                    )}
                  >
                    {t('addEmployee.form.email')}
                  </span>
                </Col>
                <Col sm={12} className={styles.formCol}>
                  <Dropdown
                    label={t('addEmployee.form.practice')}
                    options={organizationalUnits}
                    width={434}
                    error={getError('practice')}
                    errorText={Object.values(getError('practice') || {})[0]}
                    dataTestId="inputFieldPractice"
                    value={practice}
                    onChange={(event) =>
                      onChange('practice', event.target.value)
                    }
                    disable={isDisabled}
                  />
                </Col>
              </Row>
              {!isDisabled && (
                <div className={styles.Modalfooter}>
                  <Button
                    id="createEmployee"
                    data-testid="createEmployee"
                    color="primary"
                    type="button"
                    onClick={handleSubmit}
                    className={cx(styles.primaryBtn, {
                      [styles.disableBtn]: isSaving,
                    })}
                  >
                    <div>
                      <img
                        src={employee?.id || isSaving ? tickmark : plusIcon}
                        className={
                          employee?.id ? styles.editImg : styles.createImg
                        }
                        alt=""
                      />
                    </div>
                    {isSaving
                      ? `${t('addEmployee.form.saving')}...`
                      : employee?.id
                      ? t('common.save')
                      : t('common.create')}
                  </Button>{' '}
                  <Button
                    type="button"
                    color="secondary"
                    className={styles.cancelBtn}
                    onClick={closeModal}
                  >
                    {t('common.cancel')}
                  </Button>
                </div>
              )}
            </Form>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

EmployeeForm.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  nestedModal: PropTypes.bool,
  toggleNestedModal: PropTypes.func,
  employee: PropTypes.object,
};

const mapStateToProps = (state) => ({
  organizationalUnits:
    state?.organizationalUnitReducer?.organizationalUnits || [],
  fetchedRoles: state?.rolesReducer?.roles || [],
});

const mapDispatchToProps = (dispatch) => ({
  displayNotification: (notification) =>
    dispatch(displayNotificationAction(notification)),
  getOrganizationalUnits: () => dispatch(getAllOrganizationalUnit()),
  getRoles: () => dispatch(getRoles()),
});

const withConnect = (Component) =>
  connect(mapStateToProps, mapDispatchToProps)(Component);

export default withConnect(EmployeeForm);
