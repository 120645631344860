import React from 'react';
import { Modal, Label, Button } from 'reactstrap';

import styles from './viewEmployeeDetail.module.scss';
import { dashIcon, pencilIcon, darkBin } from '../../constants/icons';
import Avatar from '../Avatar';
import { useTranslation } from 'react-i18next';

const ViewPatientModal = ({
  modal,
  toggle,
  modalId,
  employee,
  onEditingInfo,
  onDeleteEmployee,
}) => {
  const {
    licenceId,
    email,
    firstName,
    lastName,
    phone,
    practice,
    employeeType,
  } = employee;
  const { t } = useTranslation();

  return (
    <div className={styles.modalSection} id={modalId}>
      <Modal
        data-testid="overviewModal"
        isOpen={modal}
        toggle={toggle}
        fade={true}
        className={styles.modalMain}
      >
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <div className={styles.hideContainer} onClick={() => toggle()}>
              <img src={dashIcon} alt="-" />
              <div className={styles.hideLabel}>{t('common.hide')}</div>
            </div>
          </div>
          <div data-testid="viewName" className={styles.profileContainerFull}>
            <div className={styles.profileContainer}>
              <Avatar employee={employee} className={styles.defImage} />
              <div className={styles.nameContainer}>
                <p className={styles.name}>{`${firstName} ${lastName}`}</p>
                <p className={styles.dob}>{licenceId}</p>
              </div>
            </div>
          </div>

          <div data-testid="viewRole">
            <Label className={styles.customformLabel}>
              {t('addEmployee.form.role')}
            </Label>
            <div className={styles.customInput}>
              {t(`employeeType.${employeeType?.type?.toLowerCase()}`)}
            </div>
          </div>
          <div className={styles.seperator} />

          <div data-testid="viewPractice">
            <Label className={styles.customformLabel}>
              {t('addEmployee.form.practice')}
            </Label>
            <div className={styles.customInput}>{practice?.label}</div>
          </div>
          <div className={styles.seperator} />

          <div data-testid="viewEmail">
            <Label className={styles.customformLabel}>
              {t('addEmployee.form.email')}
            </Label>
            <div className={styles.customInput}>{email}</div>
          </div>
          {Boolean(phone) && <div className={styles.seperator} />}

          {Boolean(phone) && (
            <>
              <div data-testid="viewPhone">
                <Label className={styles.customformLabel}>
                  {t('addEmployee.form.phone')}
                </Label>
                <div className={styles.customInput}>{phone}</div>
              </div>
            </>
          )}
        </div>
        <div className={styles.Modalfooter}>
          <Button
            id="createEmployee"
            color="primary"
            type="button"
            onClick={() => onEditingInfo(employee)}
            className={styles.primaryBtn}
          >
            <div>
              <img src={pencilIcon} alt="" />
            </div>
            {t('common.edit')}
          </Button>{' '}
          <Button
            type="button"
            color="secondary"
            className={styles.deleteButton}
            onClick={() => onDeleteEmployee(employee)}
          >
            <div>
              <img src={darkBin} alt="remove" />
            </div>
          </Button>
        </div>
      </Modal>
    </div>
  );
};

ViewPatientModal.propTypes = {};

export default ViewPatientModal;
