import { employeeTypesMap } from "../constants/employeeList";

const categoriseEmployees = (employees) => {
  if (!(employees && employees.length)) {
    return {
      doctors: [],
      trainees: [],
      assistants: [],
      allEmployees: [],
      administrators: [],
    };
  }

  const allEmployees = employees.filter(
    (employee) => employee.employeeType && !employee.archived
  );

  const doctors = allEmployees.filter(
    (employee) =>
      employee &&
      employee.employeeType &&
      employee.employeeType.id === employeeTypesMap.DOCTOR
  );

  const assistants = allEmployees.filter(
    (employee) =>
      employee &&
      employee.employeeType &&
      employee.employeeType.id === employeeTypesMap.ASSISTANT
  );

  const trainees = allEmployees.filter(
    (employee) =>
      employee &&
      employee.employeeType &&
      employee.employeeType.id === employeeTypesMap.TRAINEE
  );

  const administrators = allEmployees.filter(
    (employee) =>
      employee &&
      employee.employeeType &&
      employee.employeeType.id === employeeTypesMap.ADMINISTRATOR
  );

  return {
    allEmployees,
    doctors,
    assistants,
    trainees,
    administrators,
  };
};

export default categoriseEmployees;
