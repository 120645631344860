import React from "react";
import { NavItem, NavLink } from "reactstrap";
import classNames from "classnames";

import styles from "./navItem.module.scss";

const CustomNavItem = ({ navIndex, toggle, title, activeTab }) => {
  return (
    <NavItem id={`link_${navIndex}`} className={classNames({ active: activeTab === navIndex })}>
      <NavLink
        className={classNames({ [styles.active]: activeTab === navIndex })}
        onClick={() => {
          toggle(navIndex);
        }}
      >
        {title}
      </NavLink>
    </NavItem>
  );
};

export default CustomNavItem;
