import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dashboard from './pages/Dashboard';
import { ContextProvider } from './store/provider';
import './i18n';
import { ApolloProvider } from '@apollo/client';
import { FaunaDbService } from './services/faunadb/faunadb';

export let accessToken = '';
export let loggedInUser = '';

// @ts-ignore
window.renderEmployees = (
  containerId,
  history,
  isAuthenticated,
  token,
  options,
  authenticatedUser
) => {
  accessToken = token?.accessToken;
  loggedInUser = authenticatedUser;
  if (history && !isAuthenticated) {
    history.push('/login');
    return;
  }
  if (!history) {
    window.location.href = process.env.REACT_APP_SKELETON_URL
      ? `${process.env.REACT_APP_SKELETON_URL}employees`
      : 'http://localhost:8080/employees';
    return;
  }

  ReactGA.initialize('UA-177361705-1', {
    testMode: process.env.NODE_ENV === 'test',
  });

  console.log('Employee Management renderEmployees from microservice 1');
  getFaunaClient(accessToken).then((faunaInstance) => {
    if (faunaInstance?.client) {
      ReactDOM.render(
        <ApolloProvider client={faunaInstance?.apolloClient}>
          <ContextProvider>
            <Dashboard history={history} />
          </ContextProvider>
        </ApolloProvider>,
        document.getElementById(containerId)
      );
    }
  });
};

const getFaunaClient = (accessToken) => {
  return new Promise((resolve) => {
    const faunaInstance = FaunaDbService.initFaunaDb(accessToken);
    resolve(faunaInstance);
  });
};

// @ts-ignore
window.unmountEmployees = (containerId) => {
  console.log('Employee Management unmountEmployees from microservice');
  const node = document.getElementById(containerId);
  ReactDOM.unmountComponentAtNode(node);
};
