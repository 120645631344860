import ReactGA from 'react-ga';

const useGAEventTracker = (category = 'Category') => {
  const trackEvent = (action = 'action', label = 'label', rest = {}) => {
    ReactGA.ga('send', {
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      ...rest,
    });
  };

  return trackEvent;
};

export default useGAEventTracker;
