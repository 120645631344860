import React from 'react';
import { FormGroup, Input, FormFeedback } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import styles from './formGroupInput.module.scss';

const FormGroupInput = ({
  id,
  displayText,
  style,
  handleChange = () => null,
  value,
  disable,
  autoCompleteRef,
  validate,
  requiredError,
  onFocus,
  onFocusOut,
  dataTestId,
}) => {
  const valid =
    validate && value && value.length
      ? validate(value)
        ? false
        : true
      : false;
  const { t } = useTranslation();

  return (
    <FormGroup>
      <Input
        data-testid={dataTestId}
        onFocus={onFocus ? onFocus : () => null}
        onBlur={onFocusOut ? onFocusOut : () => null}
        id={id}
        type="text"
        innerRef={autoCompleteRef}
        placeholder={displayText}
        className={style}
        autoComplete="off"
        onChange={(event) => handleChange(event.target.value)}
        value={value}
        disabled={disable}
        invalid={valid || requiredError}
      />
      {!!requiredError && (
        <FormFeedback
          className={
            requiredError ? styles.feedbackForm : styles.feedbackFormNone
          }
        >
          {Object.values(requiredError)[0]}
        </FormFeedback>
      )}
      {!requiredError && (
        <FormFeedback
          className={
            value !== undefined &&
            value !== null &&
            (valid ? styles.feedbackForm : styles.feedbackFormNone)
          }
        >
          {t('validations.invalidInput')}
        </FormFeedback>
      )}
    </FormGroup>
  );
};

export default FormGroupInput;
