import React from "react";
import DefaultImage from "../DefaultImage";

const Avatar = ({ employee, ...props }) => {
  let avatarUrl;
  if (employee) {
    const avatar = employee.image;
    if (avatar) {
      try {
        avatarUrl = window.atob(avatar.encodedImage);
      } catch (error) {
        console.log("Error while parsing image");
      }
    }
  }

  if (!avatarUrl) return <DefaultImage {...props} />;

  if (avatarUrl.includes("data"))
    return <img src={avatarUrl} {...props} alt="avatar" id="Avatar" />;

  return <DefaultImage value={avatarUrl} {...props} />;
};

export default Avatar;