/* eslint-disable react/prop-types */
import React from "react";
import classnames from "classnames";

import { sortUp, sortDown } from "../../constants/icons.js";
import styles from "../EmployeeList/employeeList.module.scss";

const HiddenImage = ({ sortAssending }) => (
	<img
		className={styles.hidden}
		src={!sortAssending ? sortDown : sortUp}
		alt="arrow"
	></img>
);

const TableHeader = ({ 'data-testid': dataTestId, columnName, sortLabel, label, ...commonProps }) => {
	const { highlightColumn, setHighlightColumn, sortAssending, handleClick } = commonProps;

	return (
		<th
			data-testid={dataTestId}
			className={classnames({ [styles.headerFont]: highlightColumn === columnName })}
			onClick={() => {
				setHighlightColumn(columnName);
				handleClick(sortLabel);
			}}
		>
			{label}
			{highlightColumn === columnName ? (
				<img
					className={styles.arrowImage}
					src={!sortAssending ? sortDown : sortUp}
					alt="arrow"
				></img>
			) : (
					<HiddenImage sortAssending={sortAssending} />
				)}
		</th>
	);
};

export default TableHeader;
