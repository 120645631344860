export const getManipulatedRoleData = (roleData) => {

  const manipulatedData = {
    roleId: roleData?._id,
    type: roleData.type,
    auth0Id: roleData.auth0Id,
  };

  return manipulatedData;
};
