import { GET_ROLES, SET_ROLES } from "../actions/roles";

const initialState = {
  roles: [],
};

/**
 * Main function in charge of updating the state on the redux-store
 *
 * @param {object} state Contains the properties needed to be updated
 * @param {object} action Sets what should be updated on the state
 */
const RolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };

    case GET_ROLES: {
      return state;
    }
    default:
      return state;
  }
};

export default RolesReducer;
