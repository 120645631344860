import React from "react";
import { TabPane, Row, Col } from "reactstrap";

const TabPanel = ({ navIndex, children }) => {
  return (
    <TabPane tabId={navIndex}>
      <Row>
        <Col sm="12">{children}</Col>
      </Row>
    </TabPane>
  );
};

export default TabPanel;
