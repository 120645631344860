export const getManipulatedOrganizationalUnitData = (
  organizationalUnitData
) => {
  const manipulatedData = {
    id: organizationalUnitData._id,
    label: organizationalUnitData.name,
    value: organizationalUnitData._id,
    organizationalUnitId: organizationalUnitData?.ref?.value?.id,
    type: organizationalUnitData.type,
    patientServing: organizationalUnitData.patientServing,
    name: organizationalUnitData.name,
    phone: organizationalUnitData.phone,
    email: organizationalUnitData.email,
    isLegalEntity: organizationalUnitData.isLegalEntity,
  };

  return manipulatedData;
};
