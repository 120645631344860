import { FaunaDbService } from '../../../services/faunadb/faunadb';

export const checkInstanceExistByIndex = (indexName, ...props) => {
  const { client, query } = FaunaDbService.getClientAndQuery();

  return client
    .query(query.Exists(query.Match(query.Index(indexName), ...props)))
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};
