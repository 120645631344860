import axios from 'axios';
import {
  USR_GET_EMPLOYEES,
  USR_UpdateEmployee,
} from '../../constants/faunadb/functions';
import { FaunaDbService } from '../../services/faunadb/faunadb';
import { callLambdaFunction } from '../../utils/faunaDb/callLambdaFunction/callLambdaFunction';
import { getManipulatedEmployeeData } from '../../utils/manipulateData/getManipulatedEmployeeData';
import { getManipulatedOrganizationalUnitData } from '../../utils/manipulateData/getManipulatedOrganizationalUnitData';
import { GET_ALL_ORGANIZATIONAL_UNITS_QUERY } from '../gql/constant/getAllOrganizationalUnitsQuery';
import { GET_ALL_ROLES_QUERY } from '../gql/constant/getAllRolesQuery';
import { getManipulatedRoleData } from '../../utils/manipulateData/getManipulatedRoleData';
import { EMPLOYEE } from '../../constants/faunadb/collections';
import { accessToken } from '../..';

async function getAllEmployees(size, cursor) {
  return callLambdaFunction(USR_GET_EMPLOYEES, EMPLOYEE, size, cursor).then(
    (data) => {
      const afterCursor = data?.after?.[0]?.value?.id || null;
      const employees = data?.employee?.map((employeeData) =>
        getManipulatedEmployeeData(employeeData)
      );

      return { afterCursor, employees };
    }
  );
}

async function getAllOrganizationalUnits(size, cursor) {
  const { apolloClient } = FaunaDbService.getClientAndQuery();

  return apolloClient
    .query({
      query: GET_ALL_ORGANIZATIONAL_UNITS_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        _size: size,
        _cursor: cursor,
      },
    })
    .then((data) => {
      const afterCursor = data?.data?.organizationalUnits?.after;
      const employees = data?.data?.organizationalUnits?.data?.map(
        (organizationalUnitsData) =>
          getManipulatedOrganizationalUnitData(organizationalUnitsData)
      );

      return { afterCursor, employees };
    })
    .catch((error) => console.log({ error }));
}

async function getAllRoles(size, cursor) {
  const { apolloClient } = FaunaDbService.getClientAndQuery();

  return apolloClient
    .query({
      query: GET_ALL_ROLES_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        _size: size,
        _cursor: cursor,
      },
    })
    .then((data) => {
      const afterCursor = data?.data?.allRoles?.after;
      const roles = data?.data?.allRoles?.data?.map((roleData) =>
        getManipulatedRoleData(roleData)
      );
      return { afterCursor, roles };
    })
    .catch((error) => console.log({ error }));
}

async function addEmployee(employeeDetails, isGerman) {
  const languageHeader = isGerman
    ? { headers: { 'Accept-Language': 'de-DE' } }
    : {};

  return axios({
    baseURL: process.env.REACT_APP_SERVERLESS_API_GATEWAY,
    url: `/users`,
    method: 'POST',
    data: employeeDetails,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'faunadb-token': accessToken,
      'Content-Type': 'application/json',
    },
  });
}

async function deleteEmployee(employee) {
  return axios({
    baseURL: process.env.REACT_APP_SERVERLESS_API_GATEWAY,
    url: `/users`,
    method: 'DELETE',
    data: employee,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'faunadb-token': accessToken,
      'Content-Type': 'application/json',
    },
  });
}

async function updateEmployee(id, employeeDetails) {
  await callLambdaFunction(USR_UpdateEmployee, [...employeeDetails]);
  // return request({
  //   url: `/employees/${id}`,
  //   method: 'PUT',
  //   data: employeeDetails,
  // });
}

function getEmployeeTypes() {
  // return request({
  //   url: '/employeetypes',
  //   method: 'GET',
  // });
  return [
    { id: 0, code: 'administrator' },
    { id: 1, code: 'assistant' },
    { id: 2, code: 'doctor' },
    { id: 3, code: 'trainee' },
  ];
}

const ApiGatewayService = {
  addEmployee,
  getAllEmployees,
  getAllOrganizationalUnits,
  getAllRoles,
  deleteEmployee,
  getEmployeeTypes,
  updateEmployee,
};

export default ApiGatewayService;
