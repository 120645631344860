/* eslint-disable no-useless-escape */
const isValidEmailAddress = (value) => {
  // @EMP_GENERATION_START@
  return (
    null !==
    value.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  );
  // @EMP_GENERATION_END@
};
const isValidName = (value) => {
  const re = /^[a-zA-ZäüöïëÄÜÖßáéíóúàèìùòâêîôûçÇãñõÆØÅæøå. ]+(?:-[a-zA-Z ]+)*$/;
  return re.test(value);
};
const isValidPhoneNumber = (value) => {
  return null !== value.match(/^[\s\d()+\/-]{10,20}$/);
};
const alphaNumPuncStrRegEx =
  /^[ \wäüöïëÄÜÖßáéíóúàèìùòâêîôûçÇãñõÆØÅæøå()/.,:+&-]*$/;
const isAlphaNumStr = (value) => {
  return null != value.match(alphaNumPuncStrRegEx);
};

export { isValidEmailAddress, isValidName, isValidPhoneNumber, isAlphaNumStr };
