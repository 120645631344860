import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

//to detect language
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./assets/locales/en/translation.json";
import de from "./assets/locales/de/translation.json";

const baseConfig = {
  fallbackLng: "en",
  debug: true,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },

  react: {
    bindI18n: "languageChanged",
    bindI18nStore: "",
    transEmptyNodeValue: "",
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
    useSuspense: false,
  },
};

const backendConfig = {
  loadPath:
    "https://storage.googleapis.com/dechea-microservices-files/translations/{{lng}}/translation.json",
};

const resources = {
  en,
  de,
};

const testConfig = {
  resources: { ...resources },
};

const developmentConfig = {
  backend: { ...backendConfig },
};

let initConfig = {};

process.env.NODE_ENV === "test"
  ? (initConfig = { ...baseConfig, ...testConfig })
  : (initConfig = { ...baseConfig, ...developmentConfig });

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .init(initConfig);

export default i18n;
