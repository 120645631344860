import React from "react";
import { TabContent, Nav } from "reactstrap";
import { useTranslation } from "react-i18next";

import styles from "./tabs.module.scss";
import CustomNavItem from "./NavItem";
import TabPanel from "./TabPanel";
import categoriseEmployees from "../../utils/categoriseEmployees";
import EmployeeList from "../EmployeeList";

const EmployeeTabs = ({
  activeTab,
  setActiveTab,
  employees,
  onRemove,
  refetchEmployees,
  updateExistingEmployee,
}) => {
  const { t } = useTranslation();
  const {
    allEmployees,
    doctors,
    assistants,
    trainees,
    administrators,
  } = categoriseEmployees(employees);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className={styles.rootWrapper}>
      <Nav tabs className={styles["nav-tabs"]}>
        <CustomNavItem
          activeTab={activeTab}
          navIndex="1"
          toggle={toggle}
          title={t("employeeList.allEmployees")}
        />
        <CustomNavItem
          activeTab={activeTab}
          navIndex="2"
          toggle={toggle}
          title={t("employeeType.doctor_plural")}
        />
        <CustomNavItem
          activeTab={activeTab}
          navIndex="3"
          toggle={toggle}
          title={t("employeeType.assistant_plural")}
        />
        <CustomNavItem
          activeTab={activeTab}
          navIndex="4"
          toggle={toggle}
          title={t("employeeType.trainee_plural")}
        />
        <CustomNavItem
          activeTab={activeTab}
          navIndex="5"
          toggle={toggle}
          title={t("employeeType.administrator_plural")}
        />
      </Nav>
      <TabContent className={styles.contentWrapper} activeTab={activeTab}>
        <TabPanel navIndex="1">
          <EmployeeList
            data-testid="allEmployeeList"
            employees={allEmployees}
            activeTab={activeTab}
            onRemove={onRemove}
            refetchEmployees={refetchEmployees}
            updateExistingEmployee={updateExistingEmployee}
          />
        </TabPanel>
        <TabPanel navIndex="2">
          <EmployeeList
            data-testid="doctorList"
            employees={doctors}
            activeTab={activeTab}
            onRemove={onRemove}
            refetchEmployees={refetchEmployees}
            updateExistingEmployee={updateExistingEmployee}
          />
        </TabPanel>
        <TabPanel navIndex="3">
          <EmployeeList
            data-testid="assistantList"
            employees={assistants}
            activeTab={activeTab}
            onRemove={onRemove}
            refetchEmployees={refetchEmployees}
            updateExistingEmployee={updateExistingEmployee}
          />
        </TabPanel>
        <TabPanel navIndex="4">
          <EmployeeList
            data-testid="traineeList"
            employees={trainees}
            activeTab={activeTab}
            onRemove={onRemove}
            refetchEmployees={refetchEmployees}
            updateExistingEmployee={updateExistingEmployee}
          />
        </TabPanel>
        <TabPanel navIndex="5">
          <EmployeeList
            id="administratorList"
            employees={administrators}
            activeTab={activeTab}
            refetchEmployees={refetchEmployees}
            updateExistingEmployee={updateExistingEmployee}
          />
        </TabPanel>
      </TabContent>
    </div>
  );
};

export default EmployeeTabs;
