import { FaunaDbService } from '../../../services/faunadb/faunadb';

export const callLambdaFunction = (functionName, ...props) => {
  const { client, query } = FaunaDbService.getClientAndQuery();

  return client
    .query(query.Call(query.Function(functionName), ...props))
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};
