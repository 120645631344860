import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './notificationMessage.module.scss';
import utilStyles from '../../assets/css/utils.module.scss';
import { bin, tickmark } from '../../constants/icons';

export const notificationTypes = {
  NOTE_CREATED: 'NOTE_CREATED',
  NOTE_DELETED: 'NOTE_DELETED',
  FAILED: 'FAILED',
};

const NotificationMessage = ({ state, actions }) => {
  const { type, message, options } = state;
  const { dismissNotification } = actions;
  const { t } = useTranslation();

  if (!type && !message) {
    return null;
  }

  const onClickActionButton = () => {
    dismissNotification();
    if (options.actionButtonCallback) {
      options.actionButtonCallback();
    }
  };

  return (
    <div className={styles.container}>
      <span
        className={cx(
          {
            'bg-white text-primary':
              type === notificationTypes.NOTE_CREATED ||
              notificationTypes.FAILED,
            [styles.bgSecondary + ' text-white']:
              type === notificationTypes.NOTE_DELETED,
          },
          'flex-shrink-0',
          styles.notificationIcon,
          utilStyles.lh0
        )}
      >
        {/* Needs refactoring here. Just need some definite icon types success, error, warning, info, etc... */}
        {type === notificationTypes.FAILED && (
          <p className={styles.failedIconText}>X</p>
        )}
        {type === notificationTypes.NOTE_CREATED && (
          <img
            src={tickmark}
            className={styles.iconImg}
            width={20}
            height={20}
            alt="note created"
          />
        )}
        {type === notificationTypes.NOTE_DELETED && (
          <img
            className={styles.iconImg}
            src={bin}
            width={20}
            height={20}
            alt="note deleted"
          />
        )}
      </span>
      <div className="flex-grow-1">
        <span
          className={cx(styles.notificationTypeText, 'mb-2 font-weight-bold')}
        >
          {/* Need to conditionally show status here */}
          {type === notificationTypes.FAILED && t('notification.failed')}
          {type ===
            (notificationTypes.NOTE_CREATED ||
              notificationTypes.NOTE_DELETED) && t('notification.success')}
        </span>
        <div className={styles.notificationMessage}>{message}</div>
      </div>
      {options?.actionButtonText && (
        <div className="flex-shrink-0">
          <button
            type="button"
            className="btn btn-white px-3 py-1 rounded-pill text-uppercase"
            onClick={onClickActionButton}
          >
            <small className="font-weight-bold text-primary">
              {options.actionButtonText}
            </small>
          </button>
        </div>
      )}
    </div>
  );
};

export default NotificationMessage;
