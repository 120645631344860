import i18n from "../../i18n";

export const checkRequiredFields = (fields) => {
  const errors = [];
  const { firstName, lastName, role, ...restFields } = fields;

  Object.keys(restFields).forEach((fieldName) => {
    if (!restFields[fieldName]) {
      errors.push({
        [fieldName]: `${i18n.t(`addEmployee.form.${fieldName}`)} ${i18n.t(
          "validations.requiredField"
        )}`,
      });
    }
  });
  if (!role && role !== 0) {
    errors.push({
      role: `${i18n.t("addEmployee.form.role")} ${i18n.t(
        "validations.requiredField"
      )}`,
    });
  }
  if (!firstName?.trim()?.length) {
    errors.push({
      firstName: `${i18n.t("person.firstName")} ${i18n.t(
        "validations.requiredField"
      )}`,
    });
  }
  if (!lastName?.trim()?.length) {
    errors.push({
      lastName: `${i18n.t("person.lastName")} ${i18n.t("validations.requiredField")}`,
    });
  }
  return errors;
};
