import combineReducers from "../utils/combinerReducers";
import employeeReducer from "./reducers/employee";
import notificationReducer from "./reducers/notification";
import organizationalUnitReducer from "./reducers/organizationalUnits";
import rolesReducer from "./reducers/roles";

// Need to add reducers here
export const reducer = {
  employeeReducer,
  notificationReducer,
  organizationalUnitReducer,
  rolesReducer,
};

// Reducers to be combiner here similar to redux
export default combineReducers(reducer);
