import React from 'react';
import connect from "../../store/connect";
import NotificationMessage from "../../components/NotificationMessage";
import {
  dismissNotification as dismissNotificationAction,
} from "../../store/actions/notification";

const NotificationMessageContainer = ({
  state,
  dismissNotification,
}) => {
  return (
    <NotificationMessage
      state={state}
      actions={{
        dismissNotification,
      }}
    />
  );
};

const mapStateToProps = ({ notificationReducer }) => ({
  state: notificationReducer,
});

const mapDispatchToProps = (dispatch) => ({
  dismissNotification: (...args) => dispatch(dismissNotificationAction(...args)),
});

const withConnect = (Component) =>
  connect(mapStateToProps, mapDispatchToProps)(Component);

export default withConnect(NotificationMessageContainer);
