import React from "react";
import { User } from "../../constants/icons";

const DefaultImage = ({ className, value }) => {
  return (
    <img
      alt="default"
      src={User}
    />
  );
};

export default DefaultImage;
