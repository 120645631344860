import gql from 'graphql-tag';

export const GET_ALL_ORGANIZATIONAL_UNITS_QUERY = gql`
  query {
    organizationalUnits: CLS_GetAllOrganizationalUnits(_size: 10) {
      data {
        _id
        type
        patientServing
        name
        phone
        email
        isLegalEntity
      }
    }
  }
`;
