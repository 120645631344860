import React from "react";
import PropTypes from "prop-types";

import styles from "./toggleMenu.module.scss";
import OutsideClickHandler from "../OutsideClickHandler";
import { more } from "../../constants/icons";

const ToggleMenu = ({ items, isOpen, onItemClick, onClickOutside, toggleMenu }) => {
  return (
    <OutsideClickHandler actionHandler={isOpen ? onClickOutside : null}>
      <>
        <img
          src={more}
          className={styles.dots}
          onClick={toggleMenu}
          alt=":"
          data-testid="menuToggle" 
        ></img>
        {isOpen && (
          <div data-testid="toggleMenuItems" className={styles.itemGroup}>
            {items.map((item) => (
              <div
                key={item.label}
                data-testid={item.dataTestId}
                onClick={() => onItemClick(item)}
                className={styles.item}
              >
                <img src={item.logo} alt="icon" />
                <div className={styles.label}>{item.label}</div>
              </div>
            ))}
          </div>
        )}
      </>
    </OutsideClickHandler>
  );
};

ToggleMenu.propTypes = {
  items: PropTypes.array,
};

export default ToggleMenu;
