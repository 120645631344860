const employees = [
  {
    addresses: [
      {
        archived: false,
        city: 'Munich',
        country: 'Germany',
        id: 1,
        postalCode: '80331',
        street: 'Bakerstr.',
        streetNo: '66',
      },
    ],
    archived: false,
    birthdate: '1966-01-24T23:00:00+01:00',
    email: 'max@mustermann.de',
    employeeType: {
      archived: false,
      description: 'Dentist is doing dentist things',
      id: 1,
      name: 'Dentist',
      code: 'DENTIST',
    },
    firstName: 'Max',
    gender: 'Male',
    id: 1,
    image: {
      archived: false,
      id: 1,
      name: 'Avatar',
    },
    lastName: 'Mustermann',
    phone: '+49 0831 1234',
    tenant: {
      archived: false,
      description: 'Team Tausend located in Kempten...',
      id: 1,
      name: 'Tausend and Kollegen',
    },
    practice: 'test practice',
    licenceId: '123456',
    status: 'PENDING',
  },
  {
    addresses: [
      {
        archived: false,
        city: 'Indore',
        country: 'India',
        id: 2,
        postalCode: '213232',
        street: 'Market C.',
        streetNo: '32',
      },
    ],
    archived: false,
    birthdate: '1983-11-28T23:00:00+01:00',
    email: 'Ram@Sharma.de',
    employeeType: {
      archived: false,
      description: 'Dentist is doing dentist things',
      id: 1,
      name: 'Dentist',
      code: 'DENTIST',
    },
    firstName: 'Ram',
    gender: 'Male',
    id: 1,
    image: {
      archived: false,
      id: 1,
      name: 'Avatar',
    },
    lastName: 'Sharma',
    phone: '+49 0831 1234',
    tenant: {
      archived: false,
      description: 'Team Tausend located in Kempten...',
      id: 1,
      name: 'Tausend and Kollegen',
    },
    practice: 'test practice',
    licenceId: '123456',
    status: 'PENDING',
  },
  {
    addresses: [
      {
        archived: false,
        city: 'Munich',
        country: 'Germany',
        id: 1,
        postalCode: '80331',
        street: 'Bakerstr.',
        streetNo: '66',
      },
    ],
    archived: false,
    birthdate: '1966-01-24T23:00:00+01:00',
    email: 'max@mustermann.de',
    employeeType: {
      archived: false,
      description: 'Dentist is doing dentist things',
      id: 2,
      name: 'Dentist',
      code: 'DENTIST',
    },
    firstName: 'Max',
    gender: 'Male',
    id: 1,
    image: {
      archived: false,
      id: 1,
      name: 'Avatar',
    },
    lastName: 'Mustermann',
    phone: '+49 0831 1234',
    tenant: {
      archived: false,
      description: 'Team Tausend located in Kempten...',
      id: 1,
      name: 'Tausend and Kollegen',
    },
    practice: 'test practice',
    licenceId: '123456',
    status: 'PENDING',
  },
  {
    addresses: [
      {
        archived: false,
        city: 'Indore',
        country: 'India',
        id: 2,
        postalCode: '213232',
        street: 'Market C.',
        streetNo: '32',
      },
    ],
    archived: false,
    birthdate: '1983-11-28T23:00:00+01:00',
    email: 'Ram@Sharma.de',
    employeeType: {
      archived: false,
      description: 'Dentist is doing dentist things',
      id: 2,
      name: 'Dentist',
      code: 'DENTIST',
    },
    firstName: 'Ram',
    gender: 'Male',
    id: 1,
    image: {
      archived: false,
      id: 1,
      name: 'Avatar',
    },
    lastName: 'Sharma',
    phone: '+49 0831 1234',
    tenant: {
      archived: false,
      description: 'Team Tausend located in Kempten...',
      id: 1,
      name: 'Team Tausend',
    },
    practice: 'test practice',
    licenceId: '123456',
    status: 'PENDING',
  },
  {
    addresses: [
      {
        archived: false,
        city: 'Munich',
        country: 'Germany',
        id: 1,
        postalCode: '80331',
        street: 'Bakerstr.',
        streetNo: '66',
      },
    ],
    archived: false,
    birthdate: '1966-01-24T23:00:00+01:00',
    email: 'max@mustermann.de',
    employeeType: {
      archived: false,
      description: 'Dentist is doing dentist things',
      id: 3,
      name: 'Dentist',
      code: 'DENTIST',
    },
    firstName: 'Max',
    gender: 'Male',
    id: 1,
    image: {
      archived: false,
      id: 1,
      name: 'Avatar',
    },
    lastName: 'Mustermann',
    phone: '+49 0831 1234',
    tenant: {
      archived: false,
      description: 'Team Tausend located in Kempten...',
      id: 1,
      name: 'Team Tausend',
    },
    practice: 'test practice',
    licenceId: '123456',
    status: 'PENDING',
  },
  {
    addresses: [
      {
        archived: false,
        city: 'Indore',
        country: 'India',
        id: 2,
        postalCode: '213232',
        street: 'Market C.',
        streetNo: '32',
      },
    ],
    archived: false,
    birthdate: '1983-11-28T23:00:00+01:00',
    email: 'Ram@Sharma.de',
    employeeType: {
      archived: false,
      description: 'Dentist is doing dentist things',
      id: 3,
      name: 'Dentist',
      code: 'DENTIST',
    },
    firstName: 'Ram',
    gender: 'Male',
    id: 1,
    image: {
      archived: false,
      id: 1,
      name: 'Avatar',
    },
    lastName: 'Sharma',
    phone: '+49 0831 1234',
    tenant: {
      archived: false,
      description: 'Team Tausend located in Kempten...',
      id: 1,
      name: 'Team Tausend',
    },
    practice: 'test practice',
    licenceId: '123456',
    status: 'PENDING',
  },
  {
    addresses: [
      {
        archived: false,
        city: 'Munich',
        country: 'Germany',
        id: 1,
        postalCode: '80331',
        street: 'Bakerstr.',
        streetNo: '66',
      },
    ],
    archived: false,
    birthdate: '1966-01-24T23:00:00+01:00',
    email: 'max@mustermann.de',
    employeeType: {
      archived: false,
      description: 'Dentist is doing dentist things',
      id: 4,
      name: 'Dentist',
      code: 'DENTIST',
    },
    firstName: 'Max',
    gender: 'Male',
    id: 1,
    image: {
      archived: false,
      id: 1,
      name: 'Avatar',
    },
    lastName: 'Mustermann',
    phone: '+49 0831 1234',
    tenant: {
      archived: false,
      description: 'Team Tausend located in Kempten...',
      id: 1,
      name: 'Team Tausend',
    },
    practice: 'test practice',
    licenceId: '123456',
    status: 'PENDING',
  },
  {
    addresses: [
      {
        archived: false,
        city: 'Indore',
        country: 'India',
        id: 2,
        postalCode: '213232',
        street: 'Market C.',
        streetNo: '32',
      },
    ],
    archived: false,
    birthdate: '1983-11-28T23:00:00+01:00',
    email: 'Ram@Sharma.de',
    employeeType: {
      archived: false,
      description: 'Dentist is doing dentist things',
      id: 4,
      name: 'Dentist',
      code: 'DENTIST',
    },
    firstName: 'Ram',
    gender: 'Male',
    id: 1,
    image: {
      archived: false,
      id: 1,
      name: 'Avatar',
    },
    lastName: 'Sharma',
    phone: '+49 0831 1234',
    tenant: {
      archived: false,
      description: 'Team Tausend located in Kempten...',
      id: 1,
      name: 'Team Tausend',
    },
    practice: 'test practice',
    licenceId: '123456',
    status: 'PENDING',
  },
];

export default employees;

export const defaultEmployee = {
  addresses: [
    {
      archived: false,
      city: 'Munich',
      country: 'Germany',
      id: 1,
      postalCode: '80331',
      street: 'Bakerstr.',
      streetNo: '66',
    },
  ],
  archived: false,
  birthdate: '',
  email: null,
  employeeType:{
    id:'',
    auth0Id:'',
    label: '',
    name: '',
  },
  firstName: '',
  gender: 'Male',
  id: 1,
  image: {
    archived: false,
    encodedImage: null,
    id: 1,
    name: 'Avatar',
  },
  lastName: '',
  licenceId: '',
  phone: '',
  practice: '',
  status: 'PENDING',
  tenant: {
    archived: false,
    description: 'Team Tausend located in Kempten...',
    id: 1,
    name: process.env.REACT_APP_EMP_TENANT,
  },
};

export const employeeTypesMap = {
  ADMINISTRATOR: '316314389959409863',
  DOCTOR: '316314759113736392',
  ASSISTANT: '316314783936676040',
  TRAINEE: '316314835537101000',
};
