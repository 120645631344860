export const dashIcon = "https://storage.googleapis.com/dechea-microservices-files/patient-management/dashIcon.svg";
export const rigthArrow = "https://storage.googleapis.com/dechea-microservices-files/patient-management/rightArrow.svg";
export const downArrow = "https://storage.googleapis.com/dechea-microservices-files/patient-management/downArrow.svg";
export const edit = "https://storage.googleapis.com/dechea-microservices-files/patient-management/edit.svg";
export const bin = "https://storage.googleapis.com/dechea-microservices-files/patient-management/bin.svg";
export const web = "https://storage.googleapis.com/dechea-microservices-files/patient-management/webIcon.svg";
export const lock = "https://storage.googleapis.com/dechea-microservices-files/patient-management/lock.svg";
export const Cameraimg = "https://storage.googleapis.com/dechea-microservices-files/patient-management/cam.svg";
export const globe = "https://storage.googleapis.com/dechea-microservices-files/patient-management/globe.svg";
export const User = "https://storage.googleapis.com/dechea-microservices-files/employee-management/user-icon.svg";
export const whitecircletick = "https://storage.googleapis.com/dechea-microservices-files/patient-management/whitecircletick.svg";
export const sortUp = "https://storage.googleapis.com/dechea-microservices-files/patient-management/sortUp.svg";
export const sortDown = "https://storage.googleapis.com/dechea-microservices-files/patient-management/sortDown.svg";
export const plusIcon = "https://storage.googleapis.com/dechea-microservices-files/patient-management/plusIcon.svg";
export const more =
  "https://storage.googleapis.com/dechea-microservices-files/patient-management/more.svg";
export const tickmark =
  "https://storage.googleapis.com/dechea-microservices-files/patient-management/tickmark.svg";
export const pencilIcon = "https://storage.googleapis.com/dechea-microservices-files/calendar/pencil.svg";
export const darkBin = "https://storage.googleapis.com/dechea-microservices-files/employee-management/darkBin.svg";