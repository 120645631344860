import { USR_GetUserByEmail } from '../../constants/faunadb/indexes';
import { checkInstanceExistByIndex } from '../../utils/faunaDb/checkInstanceExistByIndex';

export const checkEmailExists = async (email) => {
  const isEmailExist = await checkInstanceExistByIndex(USR_GetUserByEmail, [
    email,
  ]);

  return isEmailExist;
};
