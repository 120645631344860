export const GET_ALL_ORGANIZATIONAL_UNIT = 'GET_ALL_ORGANIZATIONAL_UNIT';
export const SET_ALL_ORGANIZATIONAL_UNIT = 'SET_ALL_ORGANIZATIONAL_UNIT';

export const getAllOrganizationalUnit = () => ({
  type: GET_ALL_ORGANIZATIONAL_UNIT,
});

export const setAllOrganizationalUnit = (data) => ({
  type: SET_ALL_ORGANIZATIONAL_UNIT,
  payload: data,
});
