import React, { useState } from "react";

import ToggleMenu from "../../components/ToggleMenu";

const ToggleMenuContainer = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleItemClick = (item) => {
    setIsOpen(false);
    item.handleClick();
  };
  const handleClickOutside = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ToggleMenu
      {...props}
      isOpen={isOpen}
      onItemClick={handleItemClick}
      onClickOutside={handleClickOutside}
      toggleMenu={toggleMenu}
    />
  );
};

export default ToggleMenuContainer;
