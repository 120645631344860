import { loggedInUser } from '../index';
import { getUserNameFromAuth0 } from './getUserNameFromAuth0';

export const getManipulateEmployeeData = (employeeData) => {
  const manipulatedEmployee = {
    username: employeeData.fullName,
    invitedBy: getUserNameFromAuth0(loggedInUser).fullName,
    tenant: employeeData.practice?.name,
    to: employeeData.email,
    orgId: `${process.env.REACT_APP_ORGANIZATION_ID}`,
    roles: [employeeData.employeeType.auth0Id],
    employee: {
      data: {
        employee: {
          status: employeeData.status,
        },
        user: {
          email: employeeData.email,
          firstName: employeeData.firstName,
          lastName: employeeData.lastName,
          phone: employeeData.phone,
          image: employeeData.image.encodedImage,
        },
        organizationalUnit: employeeData.practice?.id,
        roles: [employeeData.employeeType.id],
      },
    },
  };
  return manipulatedEmployee;
};
