import gql from "graphql-tag";

export const GET_ALL_ROLES_QUERY = gql`
  query {
    allRoles: CLS_GetAllRoles(_size: 10) {
      data {
        _id
        type
        auth0Id
      }
    }
  }
`;
