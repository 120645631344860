import {
  SHOW_NOTIFICATION,
  DISMISS_NOTIFICATION,
} from "../actions/notification";

const initialState = {
  type: null,
  message: null,
  options: {
    timeout: 0,
    onDismissCallback: null,
    actionButtonText: null,
    actionButtonCallback: null,
  },
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return { ...action.payload };

    case DISMISS_NOTIFICATION:
      return { ...initialState };

    default:
      return state;
  }
};

export default notificationReducer;
