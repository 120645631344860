import React, { useEffect, useState, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { uniqWith, isEqual } from 'lodash';

import TitleBar from '../../components/TitleBar';
import EmployeeTabs from '../../components/Tabs';
import ApiGatewayService from '../../api/apiGateway';
import connect from '../../store/connect';
import { setAllEmployees } from '../../store/actions/employee';
import { displayNotification as displayNotificationAction } from '../../store/actions/notification';
import ConfirmationModal from '../../components/ConfirmationModal';
import NotificationMessage from '../../containers/NotificationMessage';
import { notificationTypes } from '../../components/NotificationMessage';
import { setAllOrganizationalUnit } from '../../store/actions/organizationalUnits';
import { setRoles } from '../../store/actions/roles';

export const Dashboard = ({
  setFetchedEmployees,
  fetchedEmployees,
  displayNotification,
  setOrganizationalUnits,
  setFetchedRoles,
}) => {
  const [activeTab, setActiveTab] = useState('1');
  const [afterCursor, setAfterCursor] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [employeeToRemove, setEmployeeToRemove] = useState(null);
  const { t } = useTranslation();

  const getEmployees = () => {
    ApiGatewayService.getAllEmployees(10, afterCursor).then((result) => {
      if (result?.employees?.length) {
        const uniqEmployee = uniqWith(
          [...fetchedEmployees, ...(result?.employees || [])],
          isEqual
        );
        setFetchedEmployees(uniqEmployee);
        if (result?.afterCursor) {
          setAfterCursor(result?.afterCursor);
        }
      }
    });
  };

  const getAllOrganizationalUnits = async () => {
    const organizationalUnits =
      await ApiGatewayService.getAllOrganizationalUnits();
    setOrganizationalUnits(organizationalUnits?.employees);
  };

  const getAllRoles = async () => {
    const roles = await ApiGatewayService.getAllRoles();
    setFetchedRoles(roles?.roles);
  };

  const handleRemove = (employee) => {
    setEmployeeToRemove(employee);
    setShowConfirmationModal(true);
  };

  const handleConfirmRemove = async () => {
  try{
    await ApiGatewayService.deleteEmployee({
      auth0Id: employeeToRemove.auth0Id,
      userId: employeeToRemove.userId,
      orgId: employeeToRemove.orgId,
      auth0InvitationId: employeeToRemove.invitationId,
    });
    const indexToRemove = fetchedEmployees.indexOf(employeeToRemove);

    displayNotification({
      type: notificationTypes.NOTE_DELETED,
      message: t('employee.removed', { employee: employeeToRemove }),
    });
    setFetchedEmployees([
      ...fetchedEmployees.slice(0, indexToRemove),
      ...fetchedEmployees.slice(indexToRemove + 1),
    ]);
    setEmployeeToRemove(null);
    setShowConfirmationModal(false);
   }catch(error){
    displayNotification({
      type: notificationTypes.FAILED,
      message: t('employee.notification.failedToDelete'),
    });
   }finally{
    setEmployeeToRemove(null);
    setShowConfirmationModal(false);
   }
  };

  const handleCancelRemove = () => {
    setEmployeeToRemove(null);
    setShowConfirmationModal(false);
  };
  const refetchEmployees = () => {
    getEmployees();
  };

  const updateExistingEmployee = (employee) => {
    const currentIndex = fetchedEmployees.findIndex(
      (emp) => employee.id === emp.id
    );
    const updatedEmployees = [...fetchedEmployees];
    updatedEmployees.splice(currentIndex, 1, employee);
    setFetchedEmployees(updatedEmployees);
  };

  useEffect(() => {
    if (afterCursor) {
      getEmployees();
    }
  }, [afterCursor]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (afterCursor === null && hasMoreData) {
  //     getEmployees();
  //   }
  // }, [afterCursor, hasMoreData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getEmployees();
    getAllOrganizationalUnits();
    getAllRoles();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Suspense fallback={<></>}>
        <TitleBar
          data-testid="employeeList-title"
          title={t('employeeList.title')}
          refetchEmployees={refetchEmployees}
        />
      </Suspense>
      <Suspense fallback={<></>}>
        <EmployeeTabs
          employees={fetchedEmployees}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          onRemove={handleRemove}
          refetchEmployees={refetchEmployees}
          updateExistingEmployee={updateExistingEmployee}
        />
      </Suspense>
      <ConfirmationModal
        isOpen={showConfirmationModal}
        onConfirm={handleConfirmRemove}
        onCancel={handleCancelRemove}
        title={t('employee.confirmRemoveTitle')}
        confirmTitle={t('common.remove')}
        cancelTitle={t('common.cancel')}
      />
      <NotificationMessage />
    </div>
  );
};

const mapStateToProps = ({ employeeReducer }) => ({
  fetchedEmployees: employeeReducer?.allEmployees,
});

const mapDispatchToProps = (dispatch) => ({
  setFetchedEmployees: (media) => dispatch(setAllEmployees(media)),
  displayNotification: (notification) =>
    dispatch(displayNotificationAction(notification)),
  setOrganizationalUnits: (units) => dispatch(setAllOrganizationalUnit(units)),
  setFetchedRoles: (roles) => dispatch(setRoles(roles)),
});

const withConnect = (Component) =>
  connect(mapStateToProps, mapDispatchToProps)(Component);

export default withConnect(Dashboard);
